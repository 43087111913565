// Assets
.button {
  padding: 16px 48px;
  text-decoration: none;
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  background: $red;
  border-color: transparent;
  color: $white;
}
.button-secondary {
  background: $lightest-grey;
  color: $darkest-grey;
}
.heading {
  @include font-size($base-h4-font-size);
  @include line-height($base-h4-line-height);
  padding: 20px;
  text-align: center;
  width: 300px;
  background: $white;
  display: block;
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -150px;
  z-index: 1000;
}
.heading-inner {
  @include font-size(32);
  @include line-height(36);
  display: block;
  background: $red;
  color: $white;
  padding: 30px 10px;
  font-weight: 700;
}
.content-block {
  padding: 80px 20px 20px 20px;
  @include breakpoint(m) {
    width: 360px;
    padding-top: 160px;
    padding-left: 30px;
    padding-right: 20px;
  }
  @include breakpoint(l) {
    width: 460px;
    padding-right: 30px;
  }
  @include breakpoint(xl) {
    width: 560px;
  }
}

.image-banner {
  background: $dark-blue;
  min-height: 100px;
}