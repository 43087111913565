// Header
.header {
  background: $darkest-grey;
  padding-top: 20px;
  padding-bottom: 20px;
  @include breakpoint(m) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
// Logo
.logo {
  width: 225px;
  height: 60px;
  display: block;
  background: url(../img/nelusoana.svg) 50% 50% no-repeat;
  transition: all .2s;
  animation-delay: .2s;
  @include animation(1s);
  @extend .fade-in-left;
  @include breakpoint(m) {
    float: left;
  }
  &:hover { opacity: .8; }
}
// Menu
.menu {
  list-style: none;
  margin: 0;
  padding: 20px 0 0 0;
  animation-delay: .2s;
  @include animation(1s);
  @extend .fade-in-right;
  @include breakpoint(m) {
    padding: 26px 0 0 0;
    float: right;
  }
  & > li {
    display: inline-block;
    margin: 0 20px 0 0;
    & > a {
      color: $light-grey;
      text-decoration: none;
      transition: all .2s;
      &:hover {
        color: $white;
      }
    }
  }
}

// Banner
.banner:before,
.banner:after,
.banner-inner:before,
.banner-inner:after {
  content: "";
  display: block;
  width: 25%;
  height: 100%;
  position: absolute;
  top: 0;
  background: $darkest-grey;
  @include animation(.3s);  
}
.banner {
  &:before {
    left: 0;
    animation-delay: .8s;
    @extend .open-curtain-down;
  }
  &:after {
    left: 25%;
    animation-delay: 1s;
    @extend .open-curtain-up;
  }
}
.banner-inner {
  &:before {
    left: 50%;
    animation-delay: 1.2s;
    @extend .open-curtain-down;
  }
  &:after {
    left: 75%;
    animation-delay: 1.4s;
    @extend .open-curtain-up;
  }
}
.banner-wrap {
  overflow: hidden;
  background: url(../img/apple-tree.jpg) 0 0 no-repeat $darkest-grey;
  background-size: cover;
  color: $white;
  position: relative;
  margin-bottom: 10px;
  padding-top: 50px;
  padding-bottom: 50px;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    @include background-alpha($dark-blue, 20%);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
  }
  @include breakpoint(m) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @include breakpoint(l) {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  @include breakpoint(xl) {
    padding-top: 180px;
    padding-bottom: 180px;
  }
}
.banner-content {
  text-align: center;
  position: relative;
  z-index: 100;
}
.banner-heading {
  @include font-size(60);
  @include line-height(72);
  word-break: break-all;
  color: $white;
  text-transform: capitalize;
  margin-bottom: 20px;
  @include breakpoint(m) {
    @include font-size(80);
    @include line-height(100);
  }
  @include breakpoint(l) {
    @include font-size(100);
    @include line-height(120);
  }
}
.banner-heading-1 {
  animation-delay: 2s;
  @include animation(1s);
  @extend .fade-in;
}
.banner-heading-2 {
  animation-delay: 2.7s;
  @include animation(1s);
  @extend .fade-in;
}
.banner-heading-3 {
  animation-delay: 3.4s;
  @include animation(1s);
  @extend .fade-in;
}
.banner-content-inner {
  @include font-size($base-h3-font-size);
  @include line-height($base-h3-line-height);
  padding: 0 20px 20px 20px;
  margin: 0 auto;
  animation-delay: 3.7s;
  @include animation(.8s);
  @extend .fade-in;
  @include breakpoint(m) {
    width: 520px;
  }
  @include breakpoint(l) {
    @include font-size($base-h2-font-size);
    @include line-height($base-h2-line-height);
    width: 600px;
  }
}
.banner-button {
  display: block;
  margin: 5px 0;
  @include breakpoint(m) {
    display: inline-block;
    margin: 0 2px;
  }
}
.button-primary {
  animation-delay: 3.9s;
  @include animation(.7s);
  @extend .fade-in-up;
}
.button-secondary {
  animation-delay: 4s;
  @include animation(.7s);
  @extend .fade-in-up;
}