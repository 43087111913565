// Critics Review

.critics-review-image-banner {
  background: url(../img/romania-2.jpg) 0 0 no-repeat;
  background-size: cover;
  @include breakpoint(m) {
    min-height: 1300px;
  }
  @include breakpoint(l) {
    min-height: 1100px;
  }
  @include breakpoint(xl) {
    min-height: 900px;
  }
}
