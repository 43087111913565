// Website Variables and Colours
$black: #000;
$white: #fff;
$red: #db3333;
$dark-blue: #01259f;
$darkest-grey: #111;
$light-grey: #ccc;
$lightest-grey: #ccc;

$base-font-family: "Source Sans Pro";
$base-heading-font-family: "Clicker Script";
$base-font-size: 18;
$base-line-height: 26;
$base-font-color: $darkest-grey;
$base-link-color: $red;
$base-link-hover-color: $dark-blue;